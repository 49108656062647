.live-btn .MuiButton-label,
.live-btn-exit .MuiButton-label {
  display: block !important;
  /* margin-top: -5px !important;
	margin-left: 10px !important; */
}
.live-btn {
  margin-left: 10px !important;
  height: 80px !important;
  border-radius: 0 !important;
  color: white !important;
  width: 80px;
  line-height: 1 !important;
}
.live-btn-exit {
  margin-left: 10px !important;
  height: 80px !important;
  border-radius: 0 !important;
  color: white !important;
  width: 80px;
  line-height: 1 !important;
}
