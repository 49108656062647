.qna_area {
  display: flex;
  padding: 100px;
  flex-direction: column;
  align-items: center;
}

.q_area {
  font-family: NanumBarunGothic;
  font-size: 75px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0d4d96;
}

.question_area {
  font-family: NanumBarunGothic;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 49px;
  margin-bottom: 53px;
}

.userinfo_area {
  font-family: NanumBarunGothic;
  font-size: 28.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #414141;
}

.qna_monitor_area {
  width: 100%;
  height: 100%;
}
