.voting_filter_row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 100px;
  margin-right: 100px;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.limit_item {
  min-width: 100px;
  max-width: 200px;
  width: 200px;
}
