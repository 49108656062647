/* animation effect */

@keyframes gifstyle {
  /* keyframes effect from ~ to 또는 0% ~ 100% 으로 구분 가능 */
  0% {
    background-size: 50% 100%;
  }
  10% {
    background-size: 50% 100.5%;
  }
  20% {
    background-size: 50% 101%;
  }
  30% {
    background-size: 50% 101.5%;
  }
  40% {
    background-size: 50% 102%;
  }
  50% {
    background-size: 50% 102.5%;
  }
  60% {
    background-size: 50% 102%;
  }
  70% {
    background-size: 50% 101.5%;
  }
  80% {
    background-size: 50% 101%;
  }
  90% {
    background-size: 50% 100.5%;
  }
  100% {
    background-size: 50% 100%;
  }
}

@keyframes gifstyle-mob {
  /* keyframes effect from ~ to 또는 0% ~ 100% 으로 구분 가능 */
  0% {
    background-size: 120%;
  }
  10% {
    background-size: 120.5%;
  }
  20% {
    background-size: 121%;
  }
  30% {
    background-size: 121.5%;
  }
  40% {
    background-size: 122%;
  }
  50% {
    background-size: 122.5%;
  }
  60% {
    background-size: 122%;
  }
  70% {
    background-size: 121.5%;
  }
  80% {
    background-size: 121%;
  }
  90% {
    background-size: 120.5%;
  }
  100% {
    background-size: 120%;
  }
}

/* animation area */

.gif-background {
  background-size: 50% 100%; /* 주고자 하는 animation effect IE 실행 시 초기 값 필요 */
  animation: gifstyle 5s infinite; /* 설정된 {{animation effect}} 를 {{N}} 초 간격으로 반영함 */
  -webkit-animation: gifstyle 5s infinite; /* IE */
}

.gif-background-mob {
  background-size: 120%; /* 주고자 하는 animation effect IE 실행 시 초기 값 필요 */
  animation: gifstyle-mob 5s infinite; /* 설정된 {{animation effect}} 를 {{N}} 초 간격으로 반영함 */
  -webkit-animation: gifstyle-mob 5s infinite; /* IE */
}
