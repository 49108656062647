html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}
p {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.jwplayer.jw-flag-aspect-mode {
  height: auto !important;
  max-height: 800px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'NanumSquareNeo-Rg';
  src: url('/fonts/NanumSquareNeoOTF-Rg.otf') format('opentype');
}

@font-face {
  font-family: 'NanumSquareNeo-Hv';
  src: url('/fonts/NanumSquareNeoOTF-Hv.otf') format('opentype');
}
