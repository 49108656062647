.titleText01 {
  font-size: 23.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  /* text-align: center; */
  color: #000000;
  margin: 0px 0px 12px 0px;
}

.titleText02 {
  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  margin: 3px 0px;
}
.titleText03 {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #9a9a9a;
  margin: 5px 0px 0px 0px;
}
.contentText {
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.34;
  letter-spacing: -0.67px;
  text-align: left;
  color: #010101;
}
.contentTextB {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}
.contentText02 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  color: #595959;
  margin: 6px 0px;
}
.contentText03 {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #818181;
  margin: 6px 0px;
}
